import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { useCustomer } from "../../customHooks/useCustomer";
import { SearchLayout } from "./SearchLayout";
import apiService from "../../service/api";
import {
    addHours,
    addMonths,
    differenceInDays,
    differenceInHours,
    format,
    getDay,
    isAfter,
    isBefore,
    isValid,
    parseISO,
    startOfDay
} from 'date-fns';
import moment from "moment";
import frLocale from "date-fns/locale/fr"
import sandbox from "../../service/Sandbox";
import { LoaderContainer } from "../components/Loader/LoaderContainer";
import useDebounceEffect from "../../customHooks/useDebounceEffect";
import { useStyleContext } from "../../customHooks/useStyleContext";

const SearchContext = React.createContext({});

const SearchProvider = () => {
    let vehicleTypes = "";

    const customerContext = useCustomer();
    const styleContext = useStyleContext();

    const outlet = useOutlet();
    const routeLocation = window.location;
    const navigate = useNavigate();
    const routerLocation = useLocation()

    const queryParams = new URLSearchParams(window.location.search) ?? {};
    const objectFromQuery = () => {
        let objectFromQuery = {};

        for (const [key, value] of queryParams.entries()) {
            objectFromQuery[key] = value;
        }
        
        return objectFromQuery;
    }
    const objectFromQueryStr = JSON.stringify(objectFromQuery());

    const [filterGroups, setFilterGroups] = React.useState([]);
    const [selectedFilterGroups, setSelectedFilterGroups] = React.useState([]);
    const [selectedFilterVehicleTypes, setSelectedFilterVehicleTypes] = React.useState([]);
    const [agencyList, setAgencyList] = React.useState([]);
    const [stepHeader, setStepHeader] = React.useState(0);
    const [loadingTasks, setLoadingTasks] = React.useState([]);
    const [filterModalOpen, setFilterModalOpen] = React.useState(false);
    const [searchModalState, setSearchModalState] = React.useState({
        mode: null,
        open: false
    });
    const [bookingPreviewLoading, setBookingPreviewLoading] = React.useState([]);
    const [bookingPreview, setBookingPreview] = React.useState();
    const [bookingPreviewCollapse, setBookingPreviewCollapse] = React.useState(false);

    const [selectedVehicle, setSelectedVehicle] = React.useState(null);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedPackOption, setSelectedPackOption] = React.useState(null);
    const [selectedTariffPackage, setSelectedTariffPackage] = React.useState(null);
    const [selectedFranchiseLevelIds, setSelectedFranchiseLevelIds] = React.useState([]);
    const [selectedKilometersStage, setSelectedKilometersStage] = React.useState(null);

    const [searchParameters, setSearchParameters] = React.useState({});
    const [vehicles, setVehicles] = React.useState([]);

    const [customer, setCustomer] = React.useState(customerContext.isLog ? customerContext.customer : {});
    const [company, setCompany] = React.useState(customerContext.company || {});

    const [searchResultData, setSearchResultData] = React.useState({
        nb_page: 0,
        total: 0,
        page: 0
    });
    const [isFirst, setIsFirst] = React.useState(true);

    const [cdwSetting, setCdwSetting] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [packOptions, setPackOptions] = React.useState([]);
    const [franchiseLevels, setFranchiseLevels] = React.useState([]);
    const [kilometersStages, setKilometersStages] = React.useState([]);
    // const [youngDriverSurcharges ,setYoungDriverSurcharges] = React.useState([])
    const [customerBirthDate, setCustomerBirthDate] = React.useState(null);

    const [selectedAgencies, setSelectedAgencies] = React.useState({
        start_agency: null,
        end_agency: null
    });

    const [couponData, setCouponData] = React.useState({});
    const [searchBookingParameters, setSearchBookingParameters] = React.useState({});

    const [initCookie, setInitCookie] = React.useState(false);

    const [minDays, setMinDays] = React.useState(0);
    const [minHours, setMinHours] = React.useState(0);
    const [minTimeBeforeStart, setMinTimeBeforeStart] = React.useState(3);
    const [maxTimeBeforeStart, setMaxTimeBeforeStart] = React.useState(12);

    const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
    const [customFieldValues, setCustomFieldValues] = React.useState({});
    const [searchDateErrors, setSearchDateErrors] = React.useState([]);

    const [materialTabIndex, setMaterialTabIndex] = React.useState(0);
    const [actualVehicleTypes, setActualVehicleTypes] = React.useState([]);

    const [formDataLoading, setFormDataLoading] = React.useState(false);
    const [showOptionImage, setShowOptionImage] = React.useState(false);
    const [showOptionGroups, setShowOptionGroups] = React.useState(false);

    const [tariffFrequency, setTariffFrequency] = React.useState("day");
    const [cookiePriceHasChanged, setCookiePriceHasChanged] = React.useState(null);
    const [cookiePriceRef, setCookiePriceRef] = React.useState(null);
    const [formData, setFormData] = React.useState(null);
    const [customerProOffline, handleCustomerProOffline] = React.useState(false);
    const [tariffPackages, setTariffPackages] = React.useState([])

    const tariffFrequencyText = React.useMemo(() => {
        if (tariffFrequency === "month") { return "mois"; }
        if (tariffFrequency === "hour") { return "heure"; }
        return "jour"
    }, [tariffFrequency]);

    const minStartDate = React.useMemo(() => addHours(new Date(), minTimeBeforeStart), [minTimeBeforeStart]);
    const maxStartDate = React.useMemo(() => addMonths(new Date(), maxTimeBeforeStart), [maxTimeBeforeStart]);

    const selectedFranchiseLevels = React.useMemo(() => {
        return franchiseLevels.filter(f => selectedFranchiseLevelIds.includes(f.id));
    }, [selectedFranchiseLevelIds, franchiseLevels]);

    const agencyExtraAmount = React.useMemo(() => {
        return (bookingPreview?.agency_extra_amount?.start ?? 0) + (bookingPreview?.agency_extra_amount?.end ?? 0);
    }, [bookingPreview]);


    const kilometersStage = React.useMemo(() => {
        return kilometersStages.find(f => f.id === selectedKilometersStage);
    }, [kilometersStages, selectedKilometersStage]);

    const searchWarningMessage = React.useMemo(() => {
        return styleContext?.configs?.["content_config-search_warning_message"] ?? null;
    }, [styleContext]);

    const loading = React.useMemo(() => {
        return loadingTasks?.length > 0;
    }, [loadingTasks]);

    const backgroundColor = React.useMemo(() => {
        return styleContext?.configs?.["content_config-bm_background_color"] ?? null
    }, [styleContext]);

    const updateAmount = () => {
        if (selectedVehicle?.category?.id && searchParameters?.start_date && searchParameters?.end_date) {
            const data = {
                category_id: selectedVehicle?.category.id,
                start_date: formatDate(searchParameters?.start_date, 'yyyy-MM-dd HH:mm', ''),
                end_date: formatDate(searchParameters?.end_date, 'yyyy-MM-dd HH:mm', ''),
                options: [...selectedOptions],
                start_agency: searchParameters.start_agency,
                end_agency: searchParameters.end_agency,
                option_pack_id: selectedPackOption,
            }
            if (couponData?.id) {
                data.coupons = [{ ...couponData }]
            }

            if (selectedFranchiseLevelIds?.length > 0) {
                const franchiseLevelIds = [...new Set(selectedFranchiseLevelIds)]
                data.franchise_levels = franchiseLevelIds.map((id) => ({ id }))
            }
            if (selectedKilometersStage) {
                data.kilometers_stage = { id: selectedKilometersStage }
            }
            if (selectedTariffPackage){
                data.tariff_package_id = selectedTariffPackage
            }
            if (styleContext?.app_config?.vehicle_search_result) {
                data.vehicle_id = selectedVehicle?.id ?? ''
            }
            if (customerBirthDate && isValid(customerBirthDate)) {
                data.customer_birth_date = format(customerBirthDate, 'yyyy-MM-dd')
            }

            const requestTime = Date.now();

            setBookingPreviewLoading(prevState => {
                return [...prevState, requestTime]
            });

            apiService.post("/api/booking/calculate-amount", data, true).then((res) => {
                if (!res || (Array.isArray(res) && res.length === 0)) { return; }

                setBookingPreview(res);
                setBookingPreviewLoading(prevState => {
                    return prevState.filter(item => item !== requestTime)
                });

                data.amount_ref = res.total_amount;

                setLocalStorage('bookingPreviewData', res);
                setLocalStorage('searchParameters', searchParameters);
                setLocalStorage('selectedVehicle', selectedVehicle);
            }).catch(() => {
                setBookingPreviewLoading(prevState => {
                    return prevState.filter(item => item !== requestTime)
                })
            })
        }
    }

    const getVehicleTypes = async () => {
        let list = []
        return apiService.post('/api/company/vehicle-types', {}, false)
            .then(res => {
                return res
            })
            .catch(() => { })
            .finally(() => {
                return list
            })
    }

    const getOptionData = (categoryId = null) => {

        const dataOptionRequest = {
            categoryId: categoryId,
            agencyId: searchParameters.start_agency,
            start_date: formatDate(searchParameters.start_date, "yyyy-MM-dd HH:mm", ""),
            end_date: formatDate(searchParameters.end_date, "yyyy-MM-dd HH:mm", ""),
        }

        if (styleContext?.app_config?.vehicle_search_result) {
            dataOptionRequest["vehicleId"] = selectedVehicle?.id ?? ''
        }

        setFormDataLoading(true)
        apiService?.get(`/api/company/booking-request-data`, dataOptionRequest, false).then(res => {

            if (res?.show_option_image != null) { setShowOptionImage(res.show_option_image) }
            if (res?.show_option_groups != null) { setShowOptionGroups(res.show_option_groups) }
            if (res?.options) { setOptions(res.options?.filter(option => option?.code_option != "cdw")) }
            if (res?.pack_options) { setPackOptions(res.pack_options) }
            if (res?.franchise_levels) { setFranchiseLevels(res.franchise_levels) }
            if (res?.pack_options) { setPackOptions(res.pack_options) }
            if (res?.kilometers_stages) { setKilometersStages(res.kilometers_stages) }
            if (res?.tariff_frequency) { setTariffFrequency(res.tariff_frequency) }
            // if (res?.young_driver_surcharges) { setYoungDriverSurcharges(res.young_driver_surcharges) }
            if (res?.tariff_packages) {setTariffPackages(res.tariff_packages)}
            
            if (!res.error) { setFormData(res) }
        }).finally(() => {
            setFormDataLoading(false);
            clearLocalStorage('searchParameters');
            clearLocalStorage('selectedVehicle');
            clearLocalStorage('bookingPreviewData');
        })
    }

    const getFilterGroups = () => {
        apiService.get("/api/search/filter-groups", [], false)
            .then((result) => {
                setFilterGroups(result)
                // console.log("DONE")
            })

        const agencyListParams = {}
        if (window.agency_code) { agencyListParams.agency_code = window.agency_code }

        apiService.get("/api/search/agency-list", agencyListParams, false)
            .then((result) => {
                // console.log("Agency List")
                if (result.agencies) {
                    setAgencyList(result.agencies)
                }
                if (result.booking_parameters) {
                    setSearchBookingParameters(result.booking_parameters)
                }
            })

    }

    const searchVehicle = (searchParams = searchParameters) => {
        let filterString = ""
        selectedFilterGroups.forEach((item, index) => {
            filterString += index === 0 ? item : `,${item}`
        })
        const data = {
            ...searchParams,
            start_date: formatDate(searchParams.start_date, "yyyy-MM-dd HH:mm", ""),
            end_date: formatDate(searchParams.end_date, "yyyy-MM-dd HH:mm", ""),
            filters: filterString,
            vehicle_types: vehicleTypes.length > 0 ? vehicleTypes : (window?.vehicle_type ?? ""),
        }
        if ((data?.start_date === '' || data?.end_date === '') && vehicles?.length > 0) {
            return false
        }
        if (window.agency) {
            data.page_agency = window.agency.id
        }
        // console.log(data)
        const loadingId = Math.random()
        setLoadingTasks(prevState => [...prevState, loadingId])
        apiService.get("/api/search", data, true)
            .then((result) => {
                setSearchResultData({
                    nb_page: result?.numberPage ?? 0,
                    total: result?.total ?? 0,
                    page: result?.paginationNumber ?? 0
                })
                setVehicles(prevState => {
                    const requestList = result?.list ?? []
                    if (result.paginationNumber == 0) {
                        return requestList
                    }
                    return [...prevState, ...requestList]
                })
            }).finally(() => setLoadingTasks(prevState => prevState.filter((item) => item !== loadingId)))
    }

    const updateBookingParametersWithAgency = (agency) => {
        let bookingMinDays = searchBookingParameters?.min_days ?? 0
        let bookingMinHours = searchBookingParameters?.min_hours ?? 0
        let bookingMinTimeBeforeStart = searchBookingParameters?.min_time_before_start ?? 3
        let bookingMaxTimeBeforeStart = searchBookingParameters?.max_time_before_start ?? 12

        // console.log(agency)
        agency?.min_days && (bookingMinDays = agency?.min_days)
        agency?.min_time_before_start && (bookingMinTimeBeforeStart = agency?.min_time_before_start)
        agency?.max_time_before_start && (bookingMaxTimeBeforeStart = agency?.max_time_before_start)

        // console.log({bookingMinDays,bookingMinTimeBeforeStart,bookingMaxTimeBeforeStart})
        setMinDays(bookingMinDays)
        setMinHours(bookingMinHours)
        setMinTimeBeforeStart(bookingMinTimeBeforeStart)
        setMaxTimeBeforeStart(bookingMaxTimeBeforeStart)
    }

    const handleFilterSelect = (filterGroupId) => {
        setSelectedFilterGroups(prevState => {
            let newState = []
            if (filterGroupId) {
                if (prevState.includes(filterGroupId)) {
                    newState = prevState.filter((item) => item !== filterGroupId)
                } else {
                    newState = [...prevState, filterGroupId]
                }
            }
            return newState
        })
    }

    const handleSearchDateAndPlaceUpdate = (data) => {
        if (agencyList?.length === 1) {
            data.start_agency = agencyList?.[0].id
            data.end_agency = agencyList?.[0].id
        }
        const formatted = formatSearchedDates(data)
        setSearchParameters(formatted)

        if (!checkMinDays(formatted)) {
            searchVehicle(formatted)
        }
    }

    const handleCustomerUpdate = (data, type = 'customer') => {
        if (type === 'customer') {
            setCustomer(data);
        } else if (type === 'custom_field') {
            setCustomFieldValues(data);
        } else if (type === 'company') {
            setCompany(data);
        }
    }

    const handleVehicleSelect = (vehicle) => {

        const formatted = formatSearchedDates(searchParameters);

        if (checkMinDays(formatted)) {
            toggleAlertDialog();
            return
        }


        setSelectedVehicle(vehicle);
        setOptions([]);

        if (vehicle.id !== selectedVehicle?.id) {
            setSelectedOptions([]);
            setSelectedPackOption(null);
            setSelectedFranchiseLevelIds([]);
            setSelectedKilometersStage(null);
            setSelectedTariffPackage(null);
        }

        if (routerLocation.pathname !== "/options") {
            navigate("/options");
        }
    }

    const setLocalStorage = (name, value) => {
        const timestamp = new Date().getTime();
        const expire = timestamp + 15 * 60 * 1000; // 15 min

        try {
            localStorage.setItem(name, btoa(JSON.stringify({ value, expire })));
        } catch {
            return null;
        }
    }

    const getLocalStorage = (name) => {
        try {
            const item = localStorage.getItem(name);

            if (!item) { return null; }

            const { value, expire } = JSON.parse(atob(item));

            if (new Date().getTime() > expire) {
                localStorage.removeItem(name);

                return null;
            }

            return value;
        } catch {
            return null;
        }
    }

    const clearLocalStorage = (name) => {
        try {
            localStorage.removeItem(name);
        } catch {
            return null;
        }
    }

    const toggleAlertDialog = () => {
        setAlertDialogOpen(prevState => !prevState)
    }

    const handleBookingSave = ({ withDrivingLicence }) => {
        const formatted = formatSearchedDates(searchParameters);

        if (checkMinDays(formatted)) {
            toggleAlertDialog();

            return;
        }

        const tmpCustomer = {
            "gender": customer.gender,
            "email": customer.email,
            "phone_number": customer.phone_number,
            "first_name": customer.first_name,
            "last_name": customer.last_name,
            "address": {
                "street_line_one": customer.address,
                "postcode": customer.postcode,
                "city": customer.city
            },
            "birthday": customer.birthday,
            "country": customer.country,
            "birth_city": customer.birth_city,
        };

        const tmpCompany = customerContext.formatAddress(company, true);

        const data = {
            "enable_amount_calculation": 1,
            "start_date": formatDate(searchParameters.start_date, "yyyy-MM-dd HH:mm", ""),
            "end_date": formatDate(searchParameters.end_date, "yyyy-MM-dd HH:mm", ""),
            "source": "fleetee_web",
            "status": 20,
            "start_agency": searchParameters.start_agency ?? '',
            "end_agency": searchParameters.end_agency ?? '',
            "customer": customerContext.isLog ? (customerContext.isPro ? tmpCompany : tmpCustomer) : (customerProOffline ? tmpCompany : tmpCustomer),
            "options": [...selectedOptions],
            "flight_number": customer.flight_number,
            "customer_comment": customer.comment,
            "pack_option": {
                "id": selectedPackOption
            },
            "drivers": customer.drivers ?? [],
            "custom_fields": customFieldValues,
            "vehicle_category": selectedVehicle?.category?.id ?? ''
        }

        if (styleContext?.app_config?.vehicle_search_result) {
            data.vehicle_id = selectedVehicle?.id ?? '';
        }
        if (selectedFranchiseLevelIds?.length > 0) {
            data.franchise_levels = selectedFranchiseLevelIds.map((id) => ({ id }));
        }
        if (selectedKilometersStage) {
            data.kilometers_stage = { id: selectedKilometersStage };
        }

        if (selectedTariffPackage){
            data.tariff_package = selectedTariffPackage
        }

        if (couponData?.id) {
            data.coupons = [{ ...couponData }];
        }
        if (withDrivingLicence) {
            data.customer.driving_license = {
                first_name: customer.first_name,
                last_name: customer.last_name,
                birthday: customer.birthday,
                license_date: customer.license_date,
                license_number: customer.license_number,
                license_country: customer.license_country
            };
        }

        createBooking(data);
    }

    const createBooking = (data) => {
        const loadingId = Math.random()
        setLoadingTasks(prevState => [...prevState, loadingId])
        apiService.post("/api/booking", data, false)
            .then((result) => {
                // console.log(result)
                if ((result.error || result.status === 500) && !result.id) {
                    if (result.code && sandbox.BOOKING_CREATE_ERROR[result.code]) {
                        setSearchDateErrors([sandbox.BOOKING_CREATE_ERROR[result.code]])
                    } else {
                        setSearchDateErrors(["Une erreur s'est produite."])
                    }
                    toggleAlertDialog()
                } else {
                    clearLocalStorage('searchParameters');
                    clearLocalStorage('selectedVehicle');
                    clearLocalStorage('bookingPreviewData');

                    if (styleContext?.mode === "booking_process") {
                        window.location.href = `/reservation?process_id_booking=${result.id}`
                    } else {
                        window.location.href = `/reservation/paiement?id=${result.id}`
                    }
                }
            }).finally(() => setLoadingTasks(prevState => prevState.filter((item) => item !== loadingId)))
    }

    const toggleFilterModal = () => {
        // console.log("toggle modal")
        setFilterModalOpen(prevState => !prevState)
    }

    const toggleSearchModal = (mode) => {
        // console.log("toggle search modal")

        setSearchModalState(prevState => {
            return {
                ...prevState,
                open: !prevState.open,
                mode: mode
            }
        })
        if (routerLocation.pathname !== "/") { navigate("/") }
    }

    const formatDate = (dateStr, dateFormat = "yyyy-MM-dd", defaultValue = "...") => {
        let res = defaultValue
        if (isValid(dateStr)) {
            res = format(dateStr, dateFormat)
        } else if (dateStr !== "") {
            const date = parseISO(dateStr)
            if (isValid(date)) {
                res = format(date, dateFormat)
            }
        }
        return res
    }

    const getAgencyWithId = (agencyId) => {
        return agencyList?.find(item => parseInt(item?.id) === parseInt(agencyId))
    }

    const checkMinDays = (data) => {
        // console.log(data)

        let startDate = data.start_date
        if (moment.isMoment(data.start_date)) {
            startDate = data.start_date.toDate()
        } else if (!isValid(data.start_date)) {
            startDate = parseISO(data.start_date)
        }

        let endDate = data.end_date
        if (moment.isMoment(data.end_date)) {
            endDate = data.end_date.toDate()
        } else if (!isValid(data.end_date)) {
            endDate = parseISO(data.end_date)
        }

        const dateDiff = differenceInDays(endDate, startDate)
        const hourDiff = differenceInHours(endDate, startDate)

        // console.log(startDate)
        // console.log(endDate)
        // console.log(dateDiff)

        let dateErrors = []

        if (minDays > 0 && Math.ceil(hourDiff / 24) < minDays) {
            dateErrors.push(`La durée minimale de location est de ${minDays} jours.`)
        }
        //MIN TIME BEFORE START
        if (isBefore(startOfDay(startDate), startOfDay(minStartDate))) {
            dateErrors.push(`Veuillez sélectionner une date de réservation à partir du ${format(minStartDate, 'dd MMMM yyyy', { locale: frLocale })} pour cette agence.`)
        }

        //MAX TIME BEFORE START
        if (isAfter(startOfDay(startDate), startOfDay(maxStartDate))) {
            dateErrors.push(`Les réservations sont possibles jusqu'à ${maxTimeBeforeStart} mois à l'avance pour cette agence.`)
        }

        setSearchDateErrors(dateErrors)
        return dateErrors.length > 0
    }

    const formatSearchedDates = (data) => {
        if (data?.start_date) {
            if (moment.isMoment(data?.start_date)) {
                data.start_date = data?.start_date.format("YYYY-MM-DD HH:mm")
            }
        }
        if (data?.end_date) {
            if (moment.isMoment(data?.end_date)) {
                data.end_date = data?.end_date.format("YYYY-MM-DD HH:mm")
            }
        }
        return data
    }

    const changeTab = (vehicle_type, tabIndex) => {
        setMaterialTabIndex(tabIndex)
        vehicleTypes = [vehicle_type]
        searchVehicle()
    }

    const handleFranchiseLevelChange = (value) => {
        // setSelectedFranchiseLevel(value)
        setSelectedFranchiseLevelIds(prevState => {
            if (value === null) { return [] }
            if (prevState.includes(value)) {
                return prevState.filter(item => item !== value);
            } else {
                const franchiseLevelItem = franchiseLevels.find(item => item.id === value);
                
                if (!franchiseLevelItem) { return prevState }

                const newState = prevState.filter(prev => prev?.id === franchiseLevelItem?.id);
                return [...newState, value]
            }
        })
    }

    const setDefaultFranchise = () => {
        let defaultFranchiseLevel = null
        let hasType = false
        let hasDefault = false
        const defaultFranchiseLevelIds = franchiseLevels?.filter(fl => {
            if (!hasType) { hasType = fl.franchise_level_type != null; }
            if (!hasDefault) { hasDefault = fl.include_in_booking === true; }
            return fl.include_in_booking === true
        })?.map(fl => fl.id) ?? []

        if ((defaultFranchiseLevelIds?.length > 0 && !hasType) || !hasDefault) {
            franchiseLevels?.forEach(fl => {
                if (!defaultFranchiseLevel || fl.price < defaultFranchiseLevel.price) {
                    defaultFranchiseLevel = fl
                }
            })
            if (defaultFranchiseLevel) {
                defaultFranchiseLevelIds.push(defaultFranchiseLevel.id)
            }
        }
        setSelectedFranchiseLevelIds(defaultFranchiseLevelIds);
    }

    const setDefaultKilometersStage = () => {
        let defaultKilometersStage = null;

        kilometersStages?.forEach(kmStage => {
            if (!defaultKilometersStage || kmStage.price_per_day < defaultKilometersStage.price_per_day) {
                defaultKilometersStage = kmStage;
            }
        });

        setSelectedKilometersStage(defaultKilometersStage?.id ?? null);
    }

    const handleKilometersStageChange = (value) => setSelectedKilometersStage(value);

    const handleTariffPackageChange = (value) => setSelectedTariffPackage(value);

    const handleBirthDateChange = (birthDate) => setCustomerBirthDate(birthDate);

    React.useEffect(() => {
        if (outlet) { return; }

        window.location.href = "/";
    }, [outlet]);

    React.useEffect(() => {
        if (selectedVehicle) {
            setInitCookie(true);

            return;
        }

        const saveSelectedVehicle = getLocalStorage("selectedVehicle");
        const saveSearchParameters = getLocalStorage("searchParameters");
        const saveBookingPreviewData = getLocalStorage("bookingPreviewData");

        if (!saveSelectedVehicle || !saveSearchParameters || !saveBookingPreviewData) {
            navigate("/");
            setInitCookie(true);

            return;
        }

        if (saveSearchParameters?.start_date && saveSearchParameters?.end_date) {
            const params = {
                start_date: parseISO(saveSearchParameters.start_date),
                end_date: parseISO(saveSearchParameters.end_date),
                start_agency: saveSearchParameters?.start_agency ?? '',
                end_agency: saveSearchParameters?.end_agency ?? '',
            };

            setSearchParameters(params);
        }
        console.log("saveBookingPreviewData===========", saveBookingPreviewData);
        

        setSelectedVehicle(saveSelectedVehicle);
        setSelectedOptions(saveBookingPreviewData?.options ?? []);
        setSelectedPackOption(saveBookingPreviewData?.option_pack_id ?? null);
        setSelectedFranchiseLevelIds(saveBookingPreviewData?.franchise_levels?.map(item => item.id) ?? []);
        setSelectedKilometersStage(saveBookingPreviewData?.kilometers_stage?.id ?? null);
        setCookiePriceRef(saveBookingPreviewData?.amount_ref ?? null);
        setSelectedTariffPackage(saveBookingPreviewData?.tariff_package?.id ?? null);

        setInitCookie(true);
    }, []);

    useDebounceEffect(() => {
        if (!initCookie) { return; }
        if (!bookingPreview) { return; }
        if (bookingPreviewLoading.length > 0) { return; }
        if (cookiePriceRef === null) { return; }
        if (cookiePriceHasChanged !== null) { return; }

        if (cookiePriceRef !== bookingPreview.total_amount && !cookiePriceHasChanged) {
            setCookiePriceHasChanged(true);

            // reset options
            setSelectedOptions([]);
            setSelectedPackOption(null);
            setSelectedFranchiseLevelIds([]);
            setSelectedKilometersStage(null);
            setSelectedTariffPackage(null);
            setDefaultFranchise();
            setDefaultKilometersStage();
            updateAmount();

            return;
        }

        setCookiePriceHasChanged(false);
    }, [bookingPreview, bookingPreviewLoading, initCookie], 500);

    React.useEffect(() => {
        if (!initCookie) { return; }

        if (customerContext.isLog && JSON.stringify(customer) !== JSON.stringify(customerContext.customer)) {
            setCustomer(customerContext.customer);
        }

        getFilterGroups()
        getOptionData(selectedVehicle?.category?.id ?? null)

        if (!selectedVehicle && routerLocation.pathname !== "/") {
            navigate("/")
        }

        if (routerLocation.pathname === "/") {
            setStepHeader(0);
            searchVehicle();
        }

        return () => {
            setFilterGroups(undefined);
        };
    }, [customerContext.isLog, initCookie]);

    React.useEffect(() => {
        let searchData = { ...searchParameters }
        if (isFirst) {
            const params = objectFromQuery();

            if (queryParams.size === 0) {
                setIsFirst(false);

                return;
            }
            // const tomorrow = addDays(startOfHour(new Date()), 1)
            // const in2days = addDays(startOfHour(new Date()), 2)

            searchData = {
                start_date: params.start_date ? params.start_date : '',
                end_date: params.end_date ? params.end_date : '',
                start_agency: params.start_agency ? params.start_agency : '',
                end_agency: params.end_agency ? params.end_agency : ''
            };

            if (window.agency) {
                searchData.start_agency = window.agency.id
                searchData.end_agency = window.agency.id
            }

            setSearchParameters(searchData);
            setIsFirst(false);
        }

        searchVehicle(searchData);
    }, [selectedFilterGroups, selectedFilterVehicleTypes]);

    React.useEffect(() => {
        if (filterGroups?.length > 0 && objectFromQueryStr !== "{}") {
            const filterGroupId = Number(objectFromQuery()?.filter_group_id);
            handleFilterSelect(filterGroupId);
        }
    }, [filterGroups, objectFromQueryStr]);

    React.useEffect(() => {
        if (selectedVehicle) {
            getOptionData(selectedVehicle?.category?.id)
        }
    }, [selectedVehicle]);

    React.useEffect(() => {
        if (agencyList.length > 0) {
            setSelectedAgencies(prevState => {
                let newState = { ...prevState }
                if (agencyList?.length === 1) {
                    newState.start_agency = agencyList?.[0]
                    newState.end_agency = agencyList?.[0]
                    setSearchParameters({
                        start_agency: newState.start_agency.id,
                        end_agency: newState.end_agency.id,
                        start_date: searchParameters.start_date,
                        end_date: searchParameters.end_date
                    })
                    searchVehicle()
                } else {
                    if (searchParameters?.start_agency) {
                        newState.start_agency = getAgencyWithId(searchParameters?.start_agency)
                    }
                    if (searchParameters?.end_agency) {
                        newState.end_agency = getAgencyWithId(searchParameters?.end_agency)
                    }
                }
                return newState
            })

            if (searchParameters?.start_agency) {
                const agency = getAgencyWithId(searchParameters?.start_agency)
                if (agency?.min_days) {
                    updateBookingParametersWithAgency(agency)
                } else {
                    updateBookingParametersWithAgency()
                }
            }
        }

    }, [agencyList, searchParameters.start_agency, searchParameters.end_agency]);

    React.useEffect(() => {
        if (agencyList?.length > 0 && !objectFromQuery()?.start_agency && !objectFromQuery()?.end_agency) {
            setSelectedAgencies(prevState => {
                let newState = { ...prevState }

                return newState
            }) // ??
            setSearchParameters(prevState => {
                let newState = { ...prevState }

                if (searchParameters?.start_date && searchParameters?.end_date) {
                    const searchData = {
                        start_date: searchParameters?.start_date,
                        end_date: searchParameters?.end_date,
                        start_agency: newState.start_agency,
                        end_agency: newState.end_agency,
                    }
                    searchVehicle(searchData)
                }
                return newState
            })
        }
    }, [agencyList]);

    React.useEffect(() => {
        if (searchParameters.start_agency && searchParameters?.start_date) {
            const startAgency = Number(searchParameters.start_agency)
            const startDate = isValid(searchParameters?.start_date) ? searchParameters?.start_date : parseISO(searchParameters?.start_date)
            const agency = agencyList.find(a => a.id == startAgency)
            const day = getDay(startDate);
            const availabilityPeriods = agency?.availability_periods

            if (availabilityPeriods?.length > 0) {
                availabilityPeriods?.forEach(period => {
                    if (period?.day === day && period.status === 'available') {

                    }
                })
            }
        }
    }, [searchParameters.start_agency, searchParameters.end_agency]);

    React.useEffect(() => {
        const defaultAgency = agencyList?.find(agency => agency?.is_default === true)

        if (defaultAgency && !searchParameters.start_agency) {
            updateBookingParametersWithAgency(defaultAgency)
        } else {
            updateBookingParametersWithAgency()
        }

    }, [searchBookingParameters]);

    useDebounceEffect(() => {
        if (!initCookie) { return; }

        updateAmount();
    }, [selectedVehicle, selectedOptions, selectedPackOption, couponData, selectedFranchiseLevelIds, selectedKilometersStage, customerBirthDate, initCookie, selectedTariffPackage], 500);

    // React.useEffect();

    React.useEffect(() => {
        setDefaultFranchise();
        // setSelectedFranchiseLevel(defaultFranchiseLevel?.id ?? null)
        // onFranchiseLevelChange && onFranchiseLevelChange(defaultFranchiseLevel?.id ?? null)
    }, [franchiseLevels]);

    React.useEffect(() => {
        if (!initCookie) { return; }
        if (selectedKilometersStage !== null) { return; }

        setDefaultKilometersStage();
    }, [kilometersStages, initCookie]);

    React.useEffect(() => {
        if (bookingPreview?.option_pack_amount?.franchise_level) {
            // setSelectedFranchiseLevel(bookingPreview?.option_pack_amount?.franchise_level?.id)
            setSelectedFranchiseLevelIds([bookingPreview?.option_pack_amount?.franchise_level?.id])
        }
        if (bookingPreview?.option_pack_amount?.kilometers_stage && selectedKilometersStage) {
            setSelectedKilometersStage(bookingPreview?.option_pack_amount?.kilometers_stage?.id)
        }
    }, [bookingPreview]);

    React.useEffect(() => {
        if (routerLocation.pathname === "/") {
            setSelectedVehicle(null)
        } else {
            setSearchModalState(prevState => ({ ...prevState, open: false }))
        }
        // trigger trackers
        let trackers = styleContext?.app_config?.trackers
        if (trackers?.length > 0) {
            const snapTracker = trackers?.find((item) => item?.provider === 'snapchat')
            const fcbkTracker = trackers?.find((item) => item?.provider === 'facebook')
            const gtagTracker = trackers?.find((item) => item?.provider === 'googletagmanager')
            if (snapTracker || fcbkTracker || gtagTracker) {
                if (routerLocation.pathname === '/') {
                    //search result = '/'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'search_result')?.forEach((event) => {
                        dataLayer.push({ event: event.event_name })
                    })
                } else if (routerLocation.pathname === '/options') {
                    // options = '/options'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'options_page')?.forEach((event) => {
                        dataLayer.push({ event: event.event_name })
                    })
                } else if (routerLocation.pathname === '/infos-client') {
                    // search_user_form = '/infos-client'
                    snapTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        snaptr('track', event.event_name)
                    })
                    fcbkTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        fbq('track', event.event_name)
                    })
                    gtagTracker?.events?.filter(evt => evt?.action_trigger === 'search_user_form')?.forEach((event) => {
                        dataLayer.push({ event: event.event_name })
                    })
                }
            }
        }
    }, [routerLocation.pathname]);

    React.useLayoutEffect(() => {
        getVehicleTypes().then(res => {
            const list = res?.map(item => ({ name: item?.name ?? "", color: item?.color ?? "#000", default_icon_name: item?.default_icon_name ?? "" }))
            setActualVehicleTypes(list)
            if (window.vehicle_type) {
                const index = list.findIndex(e => e?.name?.toLowerCase() == window?.vehicle_type.toLowerCase())
                if (index >= 0) {
                    setMaterialTabIndex(index + 1)
                }
            }
        })
    }, []);

    if (!initCookie) {
        return <LoaderContainer />;
    }

    return (
        <SearchContext.Provider
            value={{
                objectFromQueryStr,
                objectFromQuery: objectFromQuery(),
                maxTimeBeforeStart,
                customer,
                alertDialogOpen,
                customFieldValues,
                filterGroups,
                searchDateErrors,
                materialTabIndex,
                selectedFilterGroups,
                backgroundColor,
                actualVehicleTypes,
                formDataLoading,
                selectedFilterVehicleTypes,
                tariffFrequency,
                formData,
                agencyList,
                searchWarningMessage,
                stepHeader,
                loadingTasks,
                routeLocation,
                filterModalOpen,
                searchModalState,
                bookingPreviewLoading,
                bookingPreview,
                bookingPreviewCollapse,
                selectedVehicle,
                selectedOptions,
                selectedPackOption,
                selectedFranchiseLevelIds,
                selectedKilometersStage,
                searchParameters,
                vehicles,
                searchResultData,
                isFirst,
                cdwSetting,
                options,
                packOptions,
                franchiseLevels,
                kilometersStages,
                customerBirthDate,
                selectedAgencies,
                couponData,
                searchBookingParameters,
                minDays,
                minHours,
                minTimeBeforeStart,
                tariffFrequencyText,
                minStartDate,
                maxStartDate,
                agencyExtraAmount,
                selectedFranchiseLevels,
                kilometersStage,
                loading,
                cookiePriceHasChanged,
                customerProOffline,
                showOptionImage,
                showOptionGroups,
                handleCustomerProOffline,
                getVehicleTypes,
                getOptionData,
                setMinTimeBeforeStart,
                setCustomer,
                setMaxTimeBeforeStart,
                setCouponData,
                setOptions,
                setSelectedPackOption,
                setAlertDialogOpen,
                setSelectedFranchiseLevelIds,
                setPackOptions,
                setSelectedKilometersStage,
                setSearchBookingParameters,
                setSelectedVehicle,
                setSearchParameters,
                setSelectedOptions,
                setFranchiseLevels,
                setVehicles,
                setCustomFieldValues,
                setSearchResultData,
                setKilometersStages,
                setMinDays,
                setCustomerBirthDate,
                setIsFirst,
                setCdwSetting,
                setFilterGroups,
                setSelectedAgencies,
                setMinHours,
                setSearchDateErrors,
                setMaterialTabIndex,
                setActualVehicleTypes,
                setSelectedFilterGroups,
                setFormDataLoading,
                setTariffFrequency,
                setBookingPreviewLoading,
                setFormData,
                setBookingPreview,
                setSelectedFilterVehicleTypes,
                setBookingPreviewCollapse,
                updateAmount,
                setAgencyList,
                setStepHeader,
                getFilterGroups,
                setLoadingTasks,
                searchVehicle,
                setFilterModalOpen,
                updateBookingParametersWithAgency,
                setSearchModalState,
                handleFilterSelect,
                handleSearchDateAndPlaceUpdate,
                handleCustomerUpdate,
                handleVehicleSelect,
                toggleAlertDialog,
                handleBookingSave,
                createBooking,
                toggleFilterModal,
                toggleSearchModal,
                formatDate,
                getAgencyWithId,
                checkMinDays,
                formatSearchedDates,
                changeTab,
                handleFranchiseLevelChange,
                handleKilometersStageChange,
                handleBirthDateChange,
                setCookiePriceHasChanged,
                setTariffPackages,
                tariffPackages,
                selectedTariffPackage,
                setSelectedTariffPackage,
                handleTariffPackageChange,
            }}
        >
            <SearchLayout>
                {outlet}
            </SearchLayout>
        </SearchContext.Provider>
    );
};

const useSearch = () => React.useContext(SearchContext);

export { SearchProvider, useSearch };